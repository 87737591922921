<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col lg="3">
        <driver-wallet-aside
          :key="walletKey"
          :driverId="driverId"
        />
      </b-col>
      <b-col lg="9">
        <wallet @updateBalance="updateBalance()" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'WalletNew',
  components: {
    DriverWalletAside: () => import('@components/drivers/payment-info/driver-wallet-aside'),
    Wallet: () => import('@views/drivers/wallet')
  },
  data () {
    return {
      driverId: this.$route.params.id,
      modal_data: {},
      transactionModal: false,
      listTransactions: [],
      loading: true,
      limit: 5,
      listLimit: [5, 10, 20, 30],
      walletKey: 0
    }
  },
  methods: {
    updateBalance () {
      this.walletKey += 1
      console.log(this.$refs.walletBalance);
    }
  }
}
</script>

<style scoped>
.btn-border-left {
  padding-left: 0px !important;
}
</style>
