var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "3" } },
            [
              _c("driver-wallet-aside", {
                key: _vm.walletKey,
                attrs: { driverId: _vm.driverId },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "9" } },
            [
              _c("wallet", {
                on: {
                  updateBalance: function ($event) {
                    return _vm.updateBalance()
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }